import PropTypes from 'prop-types';

import { useLoggedIn } from 'bv-hooks';

import GameLaunchContainer from './game_launch_container';
import LoggedOutGameLaunch from './logged_out_game_launch';

const GameLaunchApp = (props) => {
  const loggedIn = useLoggedIn();

  if (!loggedIn) {
    return <LoggedOutGameLaunch />;
  }

  return <GameLaunchContainer {...props} />;
};

GameLaunchApp.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      gameId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default GameLaunchApp;
