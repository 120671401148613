import PropTypes from 'prop-types';

import { Button, Icon } from 'bv-components';
import { v as bvVar } from 'bv';
import { t } from 'bv-i18n';

import { useFullscreen, useQuickDepositLink } from 'bv-hooks';
import PanicButton from 'PanicButton';
import Timer from '../../../../timer';
import ProfitAndLoss from '../../../../profit_and_loss';
import CTCIcon from '../../../../ctc_icon';
import { useUKUser } from '../../hooks/use_users_by_country';

const Header = ({
  gameSessionId, gameVersionId, fullscreen, tags = [], ftp, closeAction,
}) => {
  const [toggleFullscreen, fullscreenSupported] = useFullscreen();
  const {
    panicButtonEnabled,
    timerEnabled,
    profitAndLossEnabled,
  } = bvVar('casinoV2');
  const isUKUser = useUKUser();

  const showTimer = !ftp && isUKUser && timerEnabled;
  const showProfitAndLoss = !ftp && isUKUser && tags.includes('show-in-game-session-pnl') && profitAndLossEnabled;
  const showCTC = !ftp && Object.values(bvVar('crackTheCode')).includes(true) && gameSessionId && gameVersionId;
  const redirection = useQuickDepositLink();

  return (
    <div className="gl-modal__header">
      <div className="gl-modal__header-logo">
        <a href={bvVar('rootPath')}>
          <div className="bvs-site-logo" />
        </a>
      </div>

      {panicButtonEnabled && <PanicButton source="casino-modal" />}

      <section className="gl-pl-timer-wrapper">
        {showTimer && <Timer />}
        {showProfitAndLoss && <ProfitAndLoss gameSessionId={gameSessionId} />}
      </section>
      {showCTC && (
        <CTCIcon gameSessionId={gameSessionId} gameVersionId={gameVersionId} />
      )}

      <div className="gl-modal__header-spacer" />

      <Button
        label={t('deposit')}
        href={redirection}
        className="gl-modal__deposit-button"
        primary
      />

      {fullscreen && fullscreenSupported && (
        <span
          className="bvs-link gl-modal__fullscreen"
          onClick={toggleFullscreen}
        />
      )}

      <span className="close-icon" onClick={closeAction}>
        <Icon
          type="game-launch-header-close-icon"
          className="bvs-link gl-modal__close"
          big
        />
      </span>
    </div>
  );
};

Header.propTypes = {
  gameSessionId: PropTypes.number,
  gameVersionId: PropTypes.string.isRequired,
  fullscreen: PropTypes.bool,
  tags: PropTypes.instanceOf(Array),
  ftp: PropTypes.bool,
  closeAction: PropTypes.func.isRequired,
};

Header.defaultProps = {
  gameSessionId: null,
  fullscreen: false,
  tags: [],
  ftp: false,
};

export default Header;

// TODO:
// Click on icon must probably improve
// We should use back in most of cases to close modals
// But that's not the case for other modals, so the back navigation will require 2 clicks
